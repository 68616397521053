.porabote-balloon {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1000000000;
}

.porabote-balloon_msg {
  display: flex;
  min-width: 250px;
  color: #E1B26B;
  background: #1c1717;
  padding: 45px 60px;
  margin: 6px;
  box-shadow: 2px 2px 4px 1px rgba(85, 85, 98, 0.2);
  flex-flow: row;
  align-content: space-between;
  justify-content: space-between;
  opacity: 0.85;
  font-family: "Roboto Medium", serif;
  font-size: 1.2em;
  border: 2px solid #E1B26B;
}
.porabote-balloon_msg__close {
  padding-right: 10px;
}