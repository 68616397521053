.light-theme {
  --tooltipFontColor: #3a3a3a;
  --tooltipBackground: #eaeaea;
}

.dark-theme {
  --tooltipFontColor: #EBB867;
  --tooltipBackground: #151414;
}

.prb-tooltip-menu__wrap {
  position: relative;
  display: inline;
}

.prb-tooltip-menu__drop-down-block {
  position: absolute;
  opacity: 0;
  max-height: 0;
  z-index: 1000;
  transition: all 0.3s;
  padding: 10% 0;
}
.prb-tooltip-menu__drop-down-block.active {
  opacity: 1;
  max-height: inherit;
}

.prb-tooltip-menu__drop-down-block.bottom {
  top: 80%;
}

.prb-tooltip-menu__drop-down-block.top {
  bottom: 80%;
}