@charset "utf-8";

.light-theme {
  --primaryColor: #000000;
  --secondary: #194350;
  --primaryBackground: #F1E9CE;
  --secondaryBackground: #FFFFFF;
  --primaryBorder: #000;
  --secondaryBorder: #333;
  --colorBlue: #010830;
  --colorRed: #BA010E;
  --colorBeige: #F1E9CE;
}

.dark-theme {
  --primaryColor: #FFFFFF;
  --secondary: #dddddd;
  --primaryBackground: #020202;
  --secondaryBackground: #2F3238;
  --primaryBorder: #3c415c;
  --secondaryBorder: #b4a5a5;
}

/*FONTS*/

@font-face {
  font-family: 'FEHero-Regular';
  src: url(../fonts/FEHero/FEHero-Regular.ttf);
}

@font-face {
  font-family: 'TildaSans-VF';
  src: url(../fonts/TildaSans-VF/TildaSans-VF.ttf);
}

@font-face {
  font-family: 'Roboto Medium';
  src: url(../fonts/Roboto/Roboto-Medium.ttf);
}

@font-face {
  font-family: 'Arkhip';
  src: url(../fonts/Arkhip/Arkhip-Regular.ttf);
}

/*FONTS*/

button {
  background-color: transparent;
}

:focus {
  outline: none;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #948B7D;
  font-size: 18px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

::-moz-placeholder { /* Firefox 19+ */
  color: #948B7D;
  font-size: 18px;
  font-family: TildaSans-VF, serif;
}

.no-scroll {
  margin: 0;
  max-height: 100vh;
  overflow: hidden;
}

/*RESET*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  color: var(--primaryColor);
}

a {
  text-decoration: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* Reset */

b, strong {
  font-weight: 300;
}

h1 {
  color: #333;
  font-size: 21px;
  padding: 10px 0;
  display: flex;
  align-items: center;
}


body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  line-height: 1.42857;
  font-size: 15px;
  background: linear-gradient(rgba(8, 10, 13, 1) 50%, rgba(15, 5, 5, 1) 100%);
  max-width: 100vw;
}

.layout {
  display: flex;
  flex-flow: column;
  align-items: center;
  background-image: url("https://besupr-game.com/images/design/layout-bg.jpg");
  position: relative;
  font-family: "TildaSans-VF", serif;
  background-size: 100%;
  background-repeat: no-repeat;
  max-width: 100vw;
}

.layout-block, .double-border-block {
  position: relative;
  background-color: #161519;
  border: 1px solid #E6C18A;
  max-width: 1200px;
  margin: 30px auto;
}

.content-container {
  position: relative;
  top: -12px;
  box-sizing: border-box;
  z-index: 10;
  max-width: 1100px;
  min-width: 1100px;
  background: radial-gradient(ellipse, rgba(0, 0, 0, 0.8) 50%, #0000 100%);
  margin-bottom: -15px;
  min-height: 70vh;
  @media (max-width: 768px) {
    max-width: 90vw;
    min-width: 90vw;
    margin: 0 20px;
  }
}

.double-border-block {
  position: relative;
  margin: 10px;
}

.double-border-block-vertical-top-border-colontitle, .double-border-block-vertical-bottom-border-colontitle {
  position: absolute;
  width: 100%;
  border: 1px solid #E6C18A;
  height: 40px;
  background-color: #161519;
  left: -1px;
}

.double-border-block-vertical-top-border-colontitle {
  top: -35px;
  border-bottom: 0;
}

.double-border-block-vertical-bottom-border-colontitle {
  bottom: -35px;
  border-top: 0;
}


.big-logo {
  position: relative;
  font-family: 'Arkhip', serif;
  color: #fff;
  @media (max-width: 768px) {
    display: none;
  }
}

.big-logo {
  max-width: 1200px;
  margin: 20px auto 0 auto;
  text-align: center;
  padding: 20px;
  background-size: 500px;
  background-position: 30%;
  background-repeat: no-repeat;
}

.big-logo-blur-oval {
  mask: radial-gradient(ellipse, rgba(0, 0, 0, 1) 160px, #0000 480px);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(20px);
  position: absolute;
  width: 800px;
  height: 200px;
  left: 22%;
  top: 170px;
}

.big-logo-name {
  font-size: 75px;
  line-height: 70px;
}

.big-logo-slogan {
  font-family: TildaSans-VF, serif;
  font-size: 22px;
  line-height: 36px;
  font-weight: 700;
}

/* Общие модификаторы.. */
.hidden, .hide {
  display: none;
}

.invisible {
  visibility: hidden;
}

.pd40 {
  padding: 40px;
  @media (max-width: 768px) {
    margin-bottom: 10px;
    padding: 10px;
  }
}

.pt80 {
  padding-top: 80px;
  @media (max-width: 768px) {
    padding-top: 40px;
  }
}

.link-gold {
  color: rgb(235, 184, 103);
  font-size: 1.4em;
  line-height: 50px;
  @media (max-width: 768px) {
    line-height: 30px;
    font-size: 1.2em;
  }
}

/* ..Общие модификаторы */

.head-settings-panel {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 10px 20px;
  margin-bottom: 100px;
  background: linear-gradient(rgba(4, 14, 38, 0.8) 50%, rgba(1, 7, 14, 0.5) 100%);
  border-radius: 0 0 10px 10px;
  @media (max-width: 768px) {
    margin-bottom: 40px;
  }
}

.setting_panel-link {
  cursor: pointer;
  text-align: center;
  font-weight: 600;
  padding: 0 20px;
  color: #191508;
}

.layout.light-theme {
  background: var(--primaryBackground);
}

.layout .dark-theme {
  background: var(--primaryBackground);
}

.bg-blur {
  position: absolute;
  max-width: 1200px;
  min-height: 60vh;
  bottom: 0;
  left: 5%;
  width: 90%;
  mask: radial-gradient(ellipse, rgba(0, 0, 0, 1) 50%, #0000 100%);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(20px);
  z-index: 0;
  opacity: 0.9;
}

.layout-header {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  min-height: 60px;
}

.header-panel-toggler {
  display: none;
  z-index: 1000;
  top: 60px;

  right: 0px;
  width: 130px;
  @media (max-width: 768px) {
    position: fixed;
    display: block;
    z-index: 1000;
  }
}

.header-panel {
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding: 0;
  z-index: 100;
  @media (max-width: 768px) {
    position: fixed;
    display: none;
    flex-flow: column;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    left: 0;
    top: 0;
    width: 100vw;
    margin: 0 auto;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    height: 100vh;
    padding-top: 140px;
  }
}

.header-panel.active {
  display: flex;
  @media (max-width: 768px) {
    display: flex;
  }
}

.header-panel__button {
  position: relative;
  width: 175px;
  font-size: 18px;
  height: 58px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: 400;
  box-sizing: border-box;
  background-size: cover;
  font-family: Arkhip, serif;
  margin: 0 40px;
  border-top: 1px solid #7d7467;
  border-bottom: 1px solid #7c6f6c;
  background: linear-gradient(#141416, #151517);
  @media (max-width: 768px) {
    width: 250px;
    margin-bottom: 20px;
  }
}

.header-panel__button::before {
  content: '';
  display: block;
  width: 30px;
  height: 60px;
  position: absolute;
  left: -29px;
  top: 0;
  background-image: url("@/resources/svg/navbar/item-bg-left.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.header-panel__button::after {
  content: '';
  display: block;
  width: 30.5px;
  height: 62px;
  position: absolute;
  right: -29px;
  top: -1px;
  background-image: url("@/resources/svg/navbar/item-bg-left.svg");
  background-size: contain;
  transform: rotate(180deg);
  background-repeat: no-repeat;
  background-position: bottom left;
}

.header-panel__button.right-cut {
  @media (max-width: 768px) {
    width: 130px;
    margin: 0;
    padding-right: 30px;
    font-size: 14px;
    line-height: 50px;
    height: 35px;
  }
}

.header-panel__button.right-cut::after {
  content: '';
  width: 0;
  margin-right: 0;
}

.header-panel__button.right-cut::before {
  content: '';
  display: block;
  width: 30px;
  height: 34px;
  position: absolute;
  left: -17px;
  top: 0;
  background-image: url("@/resources/svg/navbar/item-bg-left.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.header-panel__button a {
  padding-top: 5px;
  color: #F1E9CE;
  background: -webkit-linear-gradient(#C09645, #FFC977, #C09645);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  box-sizing: border-box;
}


.header-panel__logo {
  font-family: 'Roboto Medium', serif;
  font-size: 24px;
  text-transform: uppercase;
  color: var(--colorBlue);
  text-decoration: none;
  width: 300px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-wrap: nowrap;
}

.header-panel__logo__wrap {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}

/*
home page
*/

.home-page_logo-wrap {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding: 55px 0 20px 0;
  @media (max-width: 768px) {
    flex-flow: column;
    padding: 0 0 20px 0;
  }
}

.home-page_logo-img {
  width: 64px;
  padding-right: 30px;
  @media (max-width: 768px) {
    margin-top: -30px;
  }
}

.home-page_logo-slogan {
  font-family: 'Arkhip', serif;
  font-size: 44px;
  line-height: 50px;
  text-align: center;
  padding: 40px;
  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 30px;
    padding: 30px 20px;
  }
}

.slogan-white {
  font-family: 'Arkhip', serif;
  font-size: 44px;
  line-height: 50px;
  max-width: 760px;
  padding: 80px 40px 40px 40px;
  text-align: center;
  margin: 0 auto;
  @media (max-width: 768px) {
    font-size: 22px;
    padding: 40px 20px 0 20px;
    line-height: 22px;
  }
}

.slogan-gold {
  font-size: 24px;
  font-family: Arkhip, serif;
  color: #E1B26B;
  max-width: 760px;
  padding: 40px 20px;
  text-align: center;
  margin: 0 auto;
}

.home-page_slogans {
  font-family: TildaSans-VF, serif;
  font-size: 22px;
  display: flex;
  align-content: center;
  justify-content: space-between;
  padding: 0 30px;
  align-items: center;
  @media (max-width: 768px) {
    display: none;
  }
}

.home-page_slogans.mobile {
  display: none;
  @media (max-width: 768px) {
    display: flex;
    text-align: center;
    padding: 0 10px;
  }
}

/*
 *home page
*/


.layout__home-page-text {
  font-family: "TildaSans-VF", serif;
  font-size: 18px;
  padding: 40px 10%;
}

.table_title {
  font-family: "FEHero-Regular", serif;
}

.layout-body {
  padding: 0 70px;
  min-height: 50vh;
}

.table-rate {
  display: grid;
  grid-template-columns: repeat(4, 25%);
}

.table-rate_quoter_top {
  height: 140px;
}

.table-rate_quoter_top.icon {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.table-rate_quoter_score {
  border-top: 4px solid var(--colorRed);
}

.table-rate_quoter_score_title {
  font-size: 25px;
  text-align: center;
}

.table-rate_quoter_score_digital {
  font-family: "FEHero-Regular", serif;
  font-size: 120px;
  text-align: center;
  line-height: 100px;
}

.table-rate_quoter_score_digital.red {
  color: var(--colorRed);
}

.table-rate_quoter_separator-text {
  position: relative;
  font-size: 31px;
  color: var(--colorBlue);
  text-align: center;
  top: -23px;
}

.table-rate_quoter_bottom-title {
  background: var(--colorRed);
  font-size: 60px;
  font-family: "FEHero-Regular", serif;
  color: #F1E9CE;
  padding: 30px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.block_total_gamers {
  color: var(--colorBlue);
  padding: 120px 0;
  display: grid;
  grid-template-columns: 60% 40%;
}


.block_total_table_score {
  display: grid;
  grid-template-columns: 50% 50%;
  border: 4px solid var(--colorRed);
  border-bottom: 0;
  width: 100%;
}

.block_total_table_score.min {
  margin-top: 80px;
  width: 70%;
}

.block_total_table_score div {
  padding: 20px;
}

.block_total_table_score.min div {
  padding: 12px;
}


.block_total_table_score__title {
  font-size: 20px;
  border-bottom: 4px solid var(--colorRed);
  border-right: 4px solid var(--colorRed);
  font-weight: 600;
}

.block_total_table_score__title.min {
  font-size: 18px;
}

.block_total_table_score__digital {
  font-weight: 700;
  border-bottom: 4px solid var(--colorRed);
  font-size: 28px;
}

.block_total_table_score__digital.min {
  font-size: 20px;
}

.block_total_button_panel {
  padding-left: 100px;
}

.block_total_button {
  font-size: 96px;
  font-family: "FEHero-Regular", serif;
  color: var(--colorBeige);
  background: #188D24;
  text-align: center;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0 20px 20px 20px;
}

.block_total_button a {
  color: var(--colorBeige);
}


.layout-footer {
  box-sizing: border-box;
  position: relative;
  display: grid;
  grid-template-columns: 220px 1fr 200px;
  min-height: 100px;
  color: #A5A5A5;
  padding: 30px 40px;
  background: -webkit-linear-gradient(rgb(0, 0, 0, 0.4), rgb(0, 0, 0, 0.9));
  z-index: 11;
  width: 100%;
  @media (max-width: 768px) {
    display: block;
    padding: 20px;
  }
}

.layout-footer_left-block {
  color: #F1E9CE;
  font-size: 20px;
  font-family: "Roboto Medium", serif;
}

.layout-footer_left-block__logo-img {
  width: 57px;
  padding: 0 8px 0 0;
}

.layout-footer_nav-block {
  display: flex;
  align-content: center;
  justify-content: center;
  font-size: 20px;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    border-top: 1px solid #c2afa2;
    border-bottom: 1px solid #C2AFA2FF;
    padding: 10px 0 20px 0;
    margin: 10px 0;
  }
}

.layout-footer_nav-block_item {
  padding: 12px 10px 0 10px;
  color: #A5A5A5;
  font-size: 18px;
}

.layout-footer_left-block__logo {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-family: "Roboto Medium", sans-serif;
  color: var(--colorBeige);
  @media (max-width: 768px) {
    flex-flow: column;
    font-size: 14px;
    color: #F1E9CE;
  }
}

.layout-footer__links-block {
  padding: 30px 10px;
  display: flex;
  justify-content: space-between;
}

.setting_panel {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 74px 20px 70px;
  margin-bottom: 20px;
}


.auth-social-media-panel {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.auth-social-media-panel-item {
  cursor: pointer;
}

.auth-social-media-panel-item-icon {
  width: 60px;
}

.telegram-btn-wrapper {
  display: flex;
  justify-content: center;
  min-height: 50px;
}

.game-page-title {
  font-size: 48px;
  font-family: Arkhip, serif;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 26px;
  }
}

.game-page-quote-wrap {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.game-page-quote {
  max-width: 540px;
}

.game-page-quote-text {
  font-family: Arkhip, serif;
  font-size: 24px;
  color: #E1B26B;
  padding: 0 20px;
  @media (max-width: 768px) {
    font-size: 16px;
    text-align: center;
  }
}

.game-page-quote-author {
  font-size: 22px;
  font-family: TildaSans-VF, serif;
  color: #858585;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 16px;
  }
}

.porabote-table.game-table {
  padding: 0;
  font-size: 19px;
  font-family: TildaSans-VF, serif;
  @media (max-width: 768px) {
    padding: 0;
  }
}

.porabote-table.game-table a {
  color: #EBB867;
}

.games-page-add-billet {
  border: 1px solid #E6C18A;
  width: calc(100% + 100px);
  margin: 0 40px 40px -50px;
  box-sizing: content-box;
  height: 180px;
  background-image: url("https://besupr-game.com/images/design/add-billet-bg.jpg");
  background-size: cover;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  cursor: pointer;
  @media (max-width: 768px) {
    margin: 40px 20px 40px -20px;
    width: calc(100% + 40px);
  }
}

.games-page-add-billet__btn {
  background-image: url("@/resources/svg/add-billet-bg.svg");
  height: 74px;
  width: 498px;
  font-size: 24px;
  font-family: Arkhip, serif;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  background-repeat: no-repeat;
  @media (max-width: 768px) {
    height: 55px;
    width: 340px;
    font-size: 14px;
    background-size: contain;
  }
}

.games-page-add-billet__btn-text {
  color: #F1E9CE;
  background: -webkit-linear-gradient(#C09645, #FFC977, #C09645);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.home-page-empty-billet__wrap {
  margin: 60px 60px 30px 60px;
  border: 1px solid #E6C18A;
  @media (max-width: 768px) {
    margin: 30px 10px 10px 10px;
    padding: 0;
  }
}

.home-page-empty-billet {
  border: 1px solid #E6C18A;
  width: calc(100% + 40px);
  margin: 15px 20px 15px -20px;
  box-sizing: content-box;
  height: 388px;
  background-image: url("https://besupr-game.com/images/design/home-page-billet-bg.jpg");
  background-size: cover;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  @media (max-width: 768px) {
    height: 188px;
    background-size: cover;
    background-position: 50%;
    margin: 6px -20px 6px -30px;
    width: calc(100% + 60px);
  }
}


.home-page-add-billet__wrap {
  position: relative;
  margin: 60px 35px 30px 35px;
  height: 160px;
  border: 1px solid #E6C18A;
  @media (max-width: 768px) {
    padding: 10px 10px;
    margin: 30px 0 0 0;
    border: 0;
    height: 50px;
  }
}

.home-page-add-billet,
.home-page-add-billet_second {
  border: 1px solid #E6C18A;
  margin: -10px 15px 40px 15px;
  box-sizing: content-box;
  height: 180px;
  background-image: url("https://besupr-game.com/images/design/add-billet-bg.jpg");
  background-size: cover;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  @media (max-width: 768px) {
    position: relative;
    top: 10px;
    background: none;
    border: 0;
    margin: 5px;
    height: 80px;
    padding: 0;
  }
}

.home-page-add-billet_second__wrap {
  position: relative;
  margin: 60px 35px 30px 35px;
  height: 260px;
  border: 1px solid #E6C18A;
  background: radial-gradient(ellipse, rgba(0, 0, 0, 0.8) 50%, #0000 100%);
  @media (max-width: 768px) {
    margin: 20px 10px;
    width: 80vw;
    height: 160px;
    padding: 0;
  }
}

.home-page-add-billet_second {
  margin: -10px 15px 10px 15px;
  height: 280px;
  background-image: url("https://besupr-game.com/images/design/add-billet_second-bg.jpg");
  align-items: flex-end;
  @media (max-width: 768px) {
    height: 140px;
    margin: 0 -10px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border: 1px solid #E6C18A;
    padding: 0;
  }
}

.home-page-add-billet__btn,
.home-page-add_second-billet__btn {
  background-image: url("@/resources/svg/add-billet-bg.svg");
  height: 74px;
  width: 498px;
  font-size: 24px;
  font-family: Arkhip, serif;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  background-repeat: no-repeat;
  @media (max-width: 768px) {
    width: 300px;
    font-size: 16px;
    height: 54px;
    background-size: contain;
    background-position: 50%;
  }
}

.home-page-add_second-billet__btn {
  position: relative;
  top: 37px;
  @media (max-width: 768px) {
    top: 27px;
  }
}

.home-page-add-billet__btn-text {
  color: #F1E9CE;
  background: -webkit-linear-gradient(#C09645, #FFC977, #C09645);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.home-page-empty__btn-text {
  color: #F1E9CE;
  background: -webkit-linear-gradient(#C09645, #FFC977, #C09645);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.games-add-page__form {
  padding: 70px;
  @media (max-width: 768px) {
    display: block;
    padding: 30px 10px;
  }
}

.add-game-page-form-title {
  font-size: 24px;
  font-family: Arkhip, serif;
  color: #E1B26B;
  text-align: center;
  padding-bottom: 60px;
  @media (max-width: 768px) {
    padding: 0 10px 20px 10px;
  }
}

.submit-btn-gold {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  border: 1.5px solid #E1B26B;
  font-size: 18px;
  text-transform: uppercase;
  color: #E1B26B;
  font-weight: 600;
  padding: 20px 80px;
  cursor: pointer;
  transition: .4s;
  @media (max-width: 768px) {
    margin-top: 30px;
  }
}

.submit-btn-gold.min {
  font-size: 14px;
  padding: 10px 40px;
  opacity: 0.7;
}
.submit-btn-gold.min:hover {
  opacity: 1;
}

.submit-btn-gold:hover {
  background: #E1B26B;
  color: #111111;
}

.games-stats-block {
  max-width: 1200px;
  margin: 0px auto 80px auto;
}

.games-stats-back {
  border: 1px solid #E1B26B;
  background: #161519;
  padding: 15px 0;
}

.games-stats-frontblock {
  border: 1px solid #E1B26B;
  background: #161519;
  width: calc(100% + 60px);
  margin-left: -30px;
  @media (max-width: 768px) {
    width: calc(100% + 20px);
    margin-left: -10px;
  }
}

.games-stats-wrap {
  padding: 80px 0;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-areas:
          "tab-god tab-devil"
          "unfinished-games-tablo unfinished-games-tablo"
          "finished-games-tablo finished-games-tablo";

  @media (max-width: 768px) {
    padding: 40px 0;
    grid-template-columns: 100%;
    grid-template-areas:
          "tab-god"
          "unfinished-games-tablo"
          "tab-devil"
          "finished-games-tablo";
  }
}

.games-stats__tab__title {
  font-size: 41px;
  font-family: Arkhip, serif;
  color: #474649;
  text-align: right;
  padding-right: 80px;
  cursor: pointer;
  @media (max-width: 768px) {
    padding-right: 0px;
    font-size: 24px;
    text-align: center;
    color: #F1E9CE;
    background: -webkit-linear-gradient(#C09645, #FFC977, #C09645);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.games-stats__tab__title.active {
  color: #F1E9CE;
  background: -webkit-linear-gradient(#C09645, #FFC977, #C09645);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.games-stats__tabloid {
  display: none;
  grid-template-columns: 49% 35px 49%;
  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: 49% 35px 49%;
    flex-flow: column;
    margin-bottom: 30px;
  }
}

.games-stats__tabloid.active {
  display: grid;
  @media (max-width: 768px) {
    display: grid;
    flex-flow: column;
  }
}

.games-stats__half-part {
  display: grid;
  grid-template-columns: minmax(170px, 50%) minmax(50%, 80%);
  @media (max-width: 768px) {
    padding: 20px 0;
  }
}

.games-stats__half-part.god {
  padding: 40px 20px 40px 80px;
  grid-template-areas:
          "icon digital"
          "title line";
  @media (max-width: 768px) {
    display: flex;
    flex-flow: column;
    padding: 20px 10px;
    justify-content: center;
    align-items: center;
    align-content: center;
    grid-template-columns: 100%;
    grid-template-areas:
          "icon"
          "digital"
          "title";
  }
}

.games-stats__half-part.devil {
  grid-template-columns: minmax(50%, 80%) minmax(170px, 50%);
  padding: 40px 0px 40px 20px;
  grid-template-areas:
          "digital icon"
          "line title";
  @media (max-width: 768px) {
    display: flex;
    flex-flow: column;
    padding: 20px 10px;
    justify-content: center;
    align-items: center;
    align-content: center;
    grid-template-columns: 100%;
    grid-template-areas:
          "icon"
          "digital"
          "title";
  }
}

.games-stats__half-part__digital {
  font-size: 70px;
  background: -webkit-linear-gradient(#a8833e, #FFC977, #987736);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Arkhip, serif;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  @media (max-width: 768px) {
    font-size: 36px;
    text-align: left;
  }
}

.games-stats__half-part__digital.devil {
  justify-content: flex-start;
}
.games-stats__half-part__digital.god {
  justify-content: flex-end;
}

.games-stats__separator-block {
  padding: 46px 20px 0 0;
  font-size: 100px;
  background: -webkit-linear-gradient(#C09645, #FFC977, #C09645);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Arkhip, serif;
  display: flex;
  align-content: center;
  justify-content: center;
}

.games-stats__half-part__img {
  display: flex;
  flex-flow: column;
  align-content: flex-end;
  align-items: flex-end;
  justify-content: flex-end;
}

.games-stats__devil-block_tabloid__img {
  display: flex;
  flex-flow: column;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: flex-start;
}

.games-stats__half-part__img-god {
  background-image: url("https://besupr-game.com/images/design/god.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 135px;
  height: 165px;
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
}

.games-stats__half-part__img-devil {
  background-image: url("https://besupr-game.com/images/design/devil.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  width: 94px;
  height: 165px;
  align-self: flex-end;
  @media (max-width: 768px) {
    margin-bottom: 10px;
    align-self: center;
  }
}

.games-stats__half-part__title {
  font-family: TildaSans-VF, serif;
  font-size: 37px;
  text-align: center;
  padding-right: 40px;
  @media (max-width: 768px) {
    font-size: 18px;
    font-weight: 600;
  }
}

.games-stats__half-part__title.god {
  text-align: left;
  @media (max-width: 768px) {
    padding: 0;
    text-align: center;
  }
}

.games-stats__half-part__title.devil {
  text-align: left;
  padding-right: 0;
}

.games-stats__half-part__bottom-border {
  width: 137px;
  height: 6px;
  margin-top: 70px;
  padding: 0;
}


.games-stats__half-part__bottom-border-wrap {
  display: flex;
  padding-right: 0;
  align-content: center;
  @media (max-width: 768px) {
    display: none;
  }
}

.games-stats__half-part__bottom-border-wrap.god {
  justify-content: flex-end;
}

.games-stats__half-part__bottom-border-wrap.devil {
  position: relative;
  left: -20px;
  justify-content: flex-start;
}

.games-stats__half-part__bottom-border.gold {
  background-color: #FAC573;
  align-self: flex-end;
}

.games-stats__half-part__bottom-border.grey {
  background-color: #535355;
}

.games-stats__bottom-table {
  border-top: 1px solid #E6C18A;
  padding: 40px 30px;
  display: grid;
  grid-template-columns: 60% 40%;
  align-items: center;
  align-content: center;
  justify-content: center;
  @media (max-width: 768px) {
    display: flex;
    flex-flow: column;
  }
}

.games-stats__bottom-table__games_stat {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  align-items: center;
  align-content: center;
  justify-content: center;
  @media (max-width: 768px) {
    border-bottom: 1px solid #959595;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
}

.games-stats__bottom-table__gamers_stat {
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 100%;
}


.games-stats__bottom-table__block__digital {
  text-align: center;
  font-size: 35px;
  font-family: TildaSans-VF, serif;
  color: #959595;
  @media (max-width: 768px) {
    font-size: 25px;
    font-weight: 800;
  }
}

.games-stats__bottom-table__block__digital.green {
  color: #47CE54;
}

.games-stats__bottom-table__block__title {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 19px;
  font-family: TildaSans-VF, serif;
  color: #959595;
  @media (max-width: 768px) {
    font-size: 14px;
    font-weight: 500;
    margin-right: 15px;
    text-align: left;
  }
}

.games-stats__bottom-table__block__title img {
  margin-right: 12px;
  @media (max-width: 768px) {
    margin-right: 6px;
  }
}

.play-page {
  position: relative;
  top: -10px;
  max-width: 1200px;
  margin: 0 auto;
}

.borders-box-v {
  border: 1px solid #E6C18A;
  padding: 10px 20px;
  background-color: #161519;
  margin-bottom: 50px;
  height: 100%;
  z-index: 10;
  @media (max-width: 768px) {
    padding: 10px 10px;
  }
}

.borders-box-v-content {
  background-color: #161519;
  border: 1px solid #E6C18A;
  display: block;
  height: calc(100% + 100px);
  margin: -20px 0 -20px 0;
  min-height: 70vh;
}

.borders-box-v-content::after {
  content: '';
  display: block;
  padding-top: 100px;
}

.game-play-select-panel {
  display: grid;
  align-items: center;
  align-content: center;
  justify-content: center;
  grid-template-columns: 46% 46%;
  grid-gap: 50px;
  width: 580px;
  @media (max-width: 768px) {
    width: 300px;
    padding: 20px 20px;
  }
  margin: 30px auto 20px;
}

.game-play-select-panel-item {
  width: 195px;
  @media (max-width: 768px) {
    width: 135px;
  }
}

.game-play-select-panel-item {
  cursor: pointer;
  transition: .6s;

}

.game-play-select-panel-item.devil {
  opacity: 0.5;
}

.game-play-select-panel-item.devil:hover {
  opacity: 1;
}

.game-play-select-panel-img {
  width: 195px;
  height: 225px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50%;
  @media (max-width: 768px) {
    width: 135px;
    height: 155px;
  }
}

.game-play-select-panel-img.god {
  background-image: url("https://besupr-game.com/images/design/god.png");
}

.game-play-select-panel-img.devil {
  background-image: url("https://besupr-game.com/images/design/devil.png");
}

.game-play-select-panel-button {
  margin-top: 35px;
  width: 202px;
  height: 74px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  background-image: url("@/resources/svg/play-btn-bg.svg");
  cursor: pointer;
  @media (max-width: 768px) {
    width: 130px;
    height: 50px;
    background-size: contain;
  }
}

.game-play-select-panel-button-title {
  font-size: 24px;
  font-family: Arkhip, serif;
  background: -webkit-linear-gradient(#C09645, #FFC977, #C09645);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 14px;
  }
}

.game-play-select-panel-title {
  font-family: TildaSans-VF, serif;
  font-size: 19px;
  color: #959595;
  padding: 15px 0;
  text-align: center;
  font-weight: 500;
  @media (max-width: 768px) {
    font-size: 15px;
  }
}

.react-calendar {
  border: 1px solid #E6C18A;
}

.react-calendar__tile,
.react-calendar__tile--now,
.react-calendar__month-view__weekdays__weekday abbr,
.react-calendar__month-view__days__day abbr {
  color: #E6C18A;
}

.react-calendar .react-calendar__tile--now {
  background: none;
}

.react-calendar .react-calendar__tile--now:enabled:hover,
.react-calendar .react-calendar__tile--now:enabled:focus {
  background: none;
}

.react-calendar .react-calendar__tile--active {
  background: #524a48;
  color: white;
}

.react-calendar .react-calendar__navigation .react-calendar__navigation__label:enabled:hover,
.react-calendar .react-calendar__navigation .react-calendar__navigation__arrow:enabled:hover {
  background: #524a48;
}

.react-calendar button:enabled:hover {
  background: #524a48;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus,
.react-calendar__navigation__label button:enabled:focus {
  background-color: #524a48;
}

.react-calendar__navigation__label button:enabled {
  background-color: #524a48;
}

.react-calendar__navigation__label button:hover {
  background-color: #524a48;
}

.react-calendar .react-calendar__navigation button:enabled:focus {
  background-color: #524a48;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #524a48;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background-color: #524a48;
}

.react-calendar__tile--hasActive {
  background-color: #524a48;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #524a48;
}


.form-game-error {
  color: #ffbfe0;
  font-weight: 800;
  font-size: 20px;
  width: 300px;
  margin: 0 auto;
  text-align: center;
}

.profile-block {
  padding: 40px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  @media (max-width: 768px) {
    padding: 20px;
  }
}
.profile-block-title {
  color: #E6C18A;
  font-size: 50px;
  text-align: center;
  font-family: Helvetica, Sans-Serif, serif;
  @media (max-width: 768px) {
    font-size: 35px;
  }
}
.profile-block__photo {
  padding: 20px;
  display: flex;
  justify-content: center;
}

.profile-block__photo__img {
  width: 200px;
  height: 200px;
  border: 2px solid #E6C18A;
  border-radius: 50%;
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
}

.profile-block_name {
  text-align: center;
  font-size: 40px;
  padding: 20px 20px 10px 20px;
}
.profile-block_username {
  text-align: center;
  font-size: 20px;
  color: #E6C18A;
}

.profile-block .buttons-panel {
  padding: 60px 20px;
  gap: 40px;
}

.profile-details {
  display: grid;
  align-content: center;
  align-items: center;
  justify-content: center;
  grid-template-columns: 230px 1fr;
  padding: 40px 80px;
  @media (max-width: 768px) {
    display: flex;
    flex-flow: column;
    padding: 20px;
  }
}

.profile-details__list {
  margin-bottom: 20px;
}


.social-media-panel {
  min-height: 30px;
  display: flex;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
  padding-top: 10px;
  @media (max-width: 768px) {
    justify-content: center;
    padding: 20px;
  }
}

.social-media-panel__item {
  margin-left: 20px;
  width: 26px;
  height: 26px;
  background-color: #f0e9d1;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  border-radius: 6px;
  overflow: hidden;
}

.layout-footer_left-block__license {
  font-family: TildaSans-VF, serif;
  color: #F1E9CE;
  padding-left: 8px;
  padding-top: 20px;
  display: block;
  font-size: 13px;
  font-weight: 500;
  @media (max-width: 768px) {
    text-align: center;
    padding: 10px;
    color: #a19999;
    display: block;
  }
}
.layout-footer_left-block__license.right {
  text-align: right;
  padding-top: 30px;
  @media (max-width: 768px) {
    padding: 10px;
    text-align: center;
  }
}


.home-motivation-text {
  padding: 40px;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 19px;
    line-height: 30px;
  }
}

.quote-text-big {
  color: #E6C18A;
  font-size: 63px;
  font-family: Arkhip, serif;
  @media (max-width: 768px) {
    font-size: 26px;
  }
}

.quote-text-big.white {
  color: #ffffff;
}

.quote-text-big.gold {
  background: -webkit-linear-gradient(#C09645, #FFC977, #C09645);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.quote-author {
  color: #645B4C;
  font-size: 30px;
  font-family: Arkhip, serif;
}

.quote-text {
  color: #E6C18A;
  font-size: 30px;
  font-family: Arkhip, serif;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 19px;
    line-height: 24px;
  }
}

.quote-author {
  color: #645B4C;
  font-size: 30px;
  font-family: Arkhip, serif;
  @media (max-width: 768px) {
    font-size: 19px;
    line-height: 30px;
  }
}

.quote-text-small {
  font-size: 22px;
  font-family: TildaSans-VF, serif;
  color: #948B7D;
  line-height: 40px;
  padding-top: 30px;
}

.mt40 {
  margin-top: 40px;
}

.mt80 {
  margin-top: 80px;
  @media (max-width: 768px) {
    margin-top: 40px;
  }
}

.mb40 {
  margin-bottom: 40px;
}

.static-page {
  padding: 80px;
}

.play-page-timer-block {
  padding: 20px 80px 40px 80px;
  @media (max-width: 768px) {
    padding: 20px 40px;
  }
}
.timer-block {
  font-size: 80px;
  @media (max-width: 768px) {
    font-size: 40px;
  }
}

.faq-expander {
  padding: 80px;
  @media (max-width: 768px) {
    padding: 40px 20px;
  }
}
.faq-expander .prb-accordion__item__tab {
  font-size: 1.4em;
  font-weight: 500;
  color: #eae1d4;
  padding: 20px 20px;
}

.faq-expander .prb-accordion__item__tab:hover {

  color: #ffffff;
}