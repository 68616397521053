.buttons-panel {
  display: flex;
  @media (max-width: 768px) {
    flex-flow: column;
    margin-top: 20px;
  }
}

.prb-button {
  display: flex;
  align-content: center;
  justify-content: center;
  border: 1px solid #CBCDD5;
  padding: 6px;
  font-size: 1em;
  width: 100%;
  line-height: 2.5em;
  background: none no-repeat 10px 50%;
  background-size: 20px;
  box-sizing: border-box;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color ease 1s;
}

.prb-button:hover {
  background: #3f55f3;
  color: #FFFFFF;
}

.prb-button.blue {
  background: #3f55f3;
  color: #FFFFFF;
  border: 0;
}

.prb-button.blue:hover {
  background: #3246d7;
  color: #FFFFFF;
}

.prb-button.filter_btn {
  padding: 0;
  transition: background-color ease 0.5s;
}

.prb-button.filter_btn:hover {
  background: #3f55f3;
  color: #FFFFFF;
}

.submit_ok {
  padding: 2px;
  font-size: 0.8em;
  font-family: "Roboto Medium", sans-serif;
}

.filter_btn_excel {
  height: 36px;
  margin-left: 20px;
  line-height: 22px;
}

//.button_lazy-load {
//  font-family: 'Roboto Regular', serif;
//  border: 1px solid #F7F7F7;
//  color: #7A8185;
//  font-size: 13px;
//  padding: 15px;
//  text-align: center;
//  cursor: pointer;
//  width: auto;
//}
//
//.button_lazy-load.hidden {
//  display: none;
//}
//
//
//.button_lazy-load__digital {
//  padding: 0 6px;
//  font-family: 'Roboto Bold', serif;
//  color: #444;
//}
//
//.form-buttons-panel {
//  padding: 10px 0;
//  display: flex;
//}