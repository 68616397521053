.prb-slider {
  display: flex;
  background-color: #252525;
  box-sizing: border-box;
  align-items: stretch;
  align-content: stretch;
  justify-content: stretch;
  min-height: 90vh;
}

.prb-slider__slide {
  display: none;
  border: 1px solid red;
  box-sizing: border-box;
  align-items: stretch;
  align-content: stretch;
  justify-content: stretch;
}
.prb-slider__slide.active {
  display: flex;
}

.prb-slider__button-prev, .prb-slider__button-next {
  position: absolute;
  top: 45%;
  width: 50px;
  height: 50px;
  background: #101010;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0.8;
  transition: all 0.3s;
}
.prb-slider__button-prev:hover, .prb-slider__button-next:hover {
  opacity: 0.9;
}
.prb-slider__button-prev {
  left: 20px;
}

.prb-slider__button-next {
  right: 20px;
}