.light-theme {
  --selectFontColor: #000000;
  --selectBackgroundColorHover: #f1f0f0;
  --selectBackgroundColor: #FFFFFF;
  --selectBorder: 1px solid #e3e3e3;
  --selectBorderRadius: 0;
  --selectDropBoxShadow: 5px 5px 5px -5px rgba(34, 60, 80, 0.6);
}
.dark-theme {
  --selectFontColor: #E1B26B;
  --selectBackgroundColor: none;
  --selectBorder: 0;
  --selectBorderRadius: 0;
  --selectDropBoxShadow: 5px 5px 5px -5px rgba(3, 3, 3, 0.6);
  --inputFontSize: 18px;
}

.form-item__select-native {
  visibility: hidden;
  display: none;
}
.form-item__select-native {
  visibility: hidden;
}

.form-item__select-wrap {
  position: relative;
  display: flex;
  border: var(--selectBorder);
  min-height: 38px;
}

.form-item__select-custom {
  position: relative;
  display: flex;
  padding: 0;
  justify-content: space-between;
  align-items: center;
  flex-basis: 100%;
  color: var(--selectFontColor);
  background: var(--selectBackgroundColor);
  border-radius: var(--selectBorderRadius);
}

.form-item__select-custom__input {
  padding: 4px 12px 4px 20px;
  font-size: var(--inputFontSize);
  border: 0;
  width: 100%;
  line-height: 26px;
  min-width: 25px;
  height: 57px;
  background: transparent;
  color: var(--selectFontColor);
}
.form-fieldset.small .form-item__select-custom__input {
  height: 57px;
  font-size: 18px;
  border: 1px solid red;
}


div.form-item__select-custom__input {
  overflow: hidden;
}

.form-item__select-custom__input.toggle {
  cursor: pointer;
}

.form-item__select-custom__input:focus {
  border: 0;
  //box-shadow:  0px 0px 3px 0px #dbdfe2;
}

.form-item__select-custom__input:disabled {
  background: #dadada;
}

.form-item__select-custom.disable { border: 0; background: #dadada; }

.form-item__select-custom__toggle {
  top: 2px;
  right: 5px;
  display: flex;
  align-items: center;
  width: 50px;
  height: 42px;
  cursor: pointer;
}
.form-fieldset.small .form-item__select-custom__toggle {
  min-height: 20px;
  max-height: 20px;
  width: 20px;
}

.form-item__select-custom__icon {
  display: block;
  border-left: 0 solid #948B7D;
  width: 50px;
  height: 20px;
  background-image: url("./svg/select/key_down.svg");
  background-size: 18px;
  background-repeat: no-repeat;
  background-position: 10% 50%;
}

.form-item__icon-plus {
  display: block;
  width: 1px;
  height: 34px;
  background-image: url(./svg/select/plus.svg);
  background-repeat: no-repeat;
  background-size: 12px;
  background-position: 50% 50%;
  padding-left: 33px;
  color: #fff;
  overflow: hidden;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  border-left: 1px solid #CBCDD5;
}


.form-item__select__drop-blok {
  position : absolute;
  left: -1px;
  top: -6px;
  background: #181818;
  border: var(--selectBorder);
  border-radius: var(--selectBorderRadius);
  box-shadow: var(--selectDropBoxShadow);
  transition : 0.1s ease all;
  visibility : hidden;
  padding: 10px 0;
  max-height: 250px;
  overflow: scroll;
  width: 100%;
  z-index: 1000;
}

.form-item__select__drop-blok span {
  display: flex;
  flex-flow: column nowrap;
}


.form-item__select__drop-link {
  display: flex;
  padding: 6px 10px 6px 10px;
  font-size: var(--inputFontSize);
  cursor: pointer;
  color: var(--selectFontColor);
  text-decoration: none;
  align-content: flex-end;
  align-items: flex-end;
  justify-content: flex-end;
  font-family: Helvetica, Sans-Serif, serif;
  line-height: 28px;
}

.form-item__select__drop-link{
  justify-content: flex-start;
  padding-left: 20px;
}

.form-item__select__drop-link.active, .minute_set_value.active {
  background-size: 10px;
  background-repeat: no-repeat;
  background-position: 20px center;

}

.form-item__select__drop-link:hover, .minute_set_value:hover {
  cursor: pointer;
  background: #100f0f;
}

.form-item__select-custom__no-result {
  padding: 2px 10px 2px 20px;
  font-size: 13px;
  color: #a2a2a2;
  font-family: Helvetica, Sans-Serif, serif;
  width: 100%;
  flex-basis: 100%;
}

.on-select__finder {
  display: none;
}

.on-select {
  border: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  height: 34px;
  font-size: 14px;
  padding: 4px;
  cursor: pointer;
}

/*Minuts Drop List*/



/* 01-02-2018 Form Style */



.tags-info-board{
  display: flex;
  align-content: stretch;
  align-items: stretch;
  padding: 10px;
}
.tags_board__items {
  padding-left: 10px;
  display: flex;
  align-items: flex-end;
  align-content: flex-end;
  justify-content: flex-end;
}
.tags_board__item {
  font-size: 15px;
  padding-left: 24px;
  color: #000;
  background-image: url('./svg/select/tick.svg');
  background-repeat: no-repeat;
  background-size: 18px;
}

.select-tags {
  border: 1px solid #CBCDD5;
  border-top: 0;
  padding: 4px 6px;
  display: none;
}

.select-tags.active {
  display: block;
}

.select-tag {
  display: inline-block;
  background: #0a7ad6;
  margin: 4px 8px 4px 2px;
  color: #fff;
  padding: 4px 12px;
  border-radius: 2px;
}

.select-tag-close {
  padding: 0 10px 0 0;
  cursor: pointer;
}
