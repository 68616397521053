.data-source-pagination__info {
  padding: 20px 0;
}

.data-source-pagination-pages-list {
  display: flex;
}

.data-source-pagination-pages-list__page-link {
  padding: 8px;
  color: #EBB867;
  cursor: pointer;
  font-size: 1.1em;
}

.data-source-pagination-pages-list__page-link.active {
  color: #313131;
}
.data-source-pagination-pages-list__page-link:hover {
  color: #e8e8e8;
}