.profile__drop-down-menu {
  background-color: #232a2f;
  border-radius: 8px;
  display: flex;
  flex-flow: column;
  width: 100%;
  flex-wrap: nowrap;
  padding: 10px 0;
}
.profile__drop-down-menu__item {
  font-weight: 500;
  padding: 10px 20px;
}
.profile__drop-down-menu__item {
  display: flex;
  align-items: center;
  align-content: center;
  cursor: pointer;
  min-width: 200px;
  color: #e7e7e7;
}
.profile__drop-down-menu__item:hover {
  color: #ffffff;
}

.profile__drop-down-menu__item-icon {
  padding-right: 14px;
}