.porabote-content-expander-text {
  display: inline-block;
  width: 90%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  transition: 1.9s;
  max-height: 50px;
}

.porabote-content-expander-text.active {
  white-space: initial;
  overflow: visible;
  text-overflow: initial;
  max-height: 5000px;
}