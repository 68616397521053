.checkbox-toggle-wrap-gold {
  display: flex;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  color: #948B7D;
  height: 42px;
  padding: 10px 20px;
}


.checkbox-toggle-wrap-gold input[type="checkbox"] {
  visibility: hidden;
  display: none;
  border: 1px solid #E1B26B;
}

.checkbox-toggle-wrap-gold *,
.checkbox-toggle-wrap-gold ::after,
.checkbox-toggle-wrap-gold ::before {
  box-sizing: border-box;
}

/* The checkbox-toggle-gold-label - the box around the checkbox-toggle-gold-slider */
.checkbox-toggle-wrap-gold .checkbox-toggle-gold-label {
  font-size: 17px;
  position: relative;
  display: inline-block;
  width: 89px;
  height: 30px;
  margin-left: 10px;
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 10px;
    width: 66px;
  }
}

/* Hide default HTML checkbox */
.checkbox-toggle-wrap-gold .checkbox-toggle-gold-label input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.checkbox-toggle-wrap-gold .checkbox-toggle-gold-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  border: 1px solid #8E8E8E;
  transition: .4s;
  width: 65px;
}

.checkbox-toggle-wrap-gold .checkbox-toggle-gold-slider:before {
  position: absolute;
  content: "";
  height: 28px;
  width: 32px;
  left: 0;
  top: 0px;
  background-color: #adb5bd;
  transition: .4s;
}

//.checkbox-toggle-wrap-gold input:checked + .checkbox-toggle-gold-slider {
//  background-color: #007bff;
//  border: 1px solid #007bff;
//}
//
//.checkbox-toggle-wrap-gold input:focus + .checkbox-toggle-gold-slider {
//  box-shadow: 0 0 1px #007bff;
//}

.checkbox-toggle-wrap-gold input:checked + .checkbox-toggle-gold-slider:before {
  transform: translateX(33px);
  background-color: #E1B26B;
}