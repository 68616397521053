.upload__label-default {
  background-image: url('../svg/up-arrow.svg');
  font-family: Roboto Regular, serif;
  background-repeat: no-repeat;
  background-position: 50% 16px;
  background-size: 40px;
  display: block;
  width: 100%;
  cursor: pointer;
  border: 2px dashed #c5c9cd;
  padding: 64px 0 20px 0;
  font-size: 14px;
  text-align: center;
  color: #acb1b6;
}

.upload__upload-input-default {
  visibility: hidden;
  display: none;
}

/* Gallery Base */
.preview-panel {
  padding: 20px 0;
}

.preview-panel-items {

}
.preview-panel-item {
  display: grid;
  grid-template-areas:
          "file_img file_name"
          "file_img file-size"
          "file_img file-fields";
  grid-template-columns: 120px;
  border-bottom: 1px dashed #dedede;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.preview-panel-item:last-child {
  border: 0;
}

.preview-panel-file-cover {
  width: 80px;
  height: 80px;
  background-size: cover;
  grid-area: file_img;
  font-size: 12px;
  font-weight: 900;
  border: 1px solid #fafafa;
  border-radius: 2px;
  text-transform: uppercase;
  color: #0672d9;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  box-shadow: 0px -2px 2px rgba(116, 116, 119, 0.4);
  margin-right: 20px;
}

.preview-panel-file-name {
  grid-area: file_name;
  font-size: 13px;
}
.preview-panel-file-size {
  grid-area: file-size;
  font-size: 12px;
  color: #a8afb5;
}

.preview-panel-file-fields {
  grid-area: file-fields;
}



/* Прелоадер при загрузке файлов */

#preloader-area {
  border: 0px solid #f6f6f6;
  width: 100%;
  padding: 0;
}

.preloader-area__item {
  display: flex;
  flex-flow: column nowrap;
  background: #f6f6f6;
  width: 100%;
  padding: 10px 4px;
  font-size: 12px;
  margin-top: 6px;
}

.preloader-area__data {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  padding-bottom: 10px;
  color: #6f8085;
}

.preloader-area__data-name {
  flex-basis: 90%;
}

.preloader-area__data-percent {
  flex-basis: 5%;
}

.preloader-area__close {
  display: blok;
  flex-basis: 10px;
  align-self: flex-end;
  color: #8b7ce6;
  padding-left: 15px;
  width: 10px;
  height: 20px;
  background-image: url('../svg/close.svg');
  background-repeat: no-repeat;
  background-size: 12px;
  background-position: 50% 50%;
}

.preloader-area__item span.color-progress {
  padding: 0;
  margin: 10px 0;
  display: block;
  float: none;
  width: 100%;
  border: 1px solid #e5e5e5;
}
.preloader-area__item span.color-label {
  position: relative;
  background: #8b7ce6;
  height: 3px;
  padding: 0;
  display: block;
  float: none;
  width: 1%;
}

.preview-panel__file-icon {
  color: #3171F6;
  position: relative;
  top: 8px;
  margin-right: 6px;
}

.preview-panel__cell-delete {
  display: flex;
  justify-content: center;
  align-items: center;
}

.preview-panel__delete-icon {
  color: #d2d5d9;
}
.preview-panel__delete-icon:hover {
  color: #3171F6;
  cursor: pointer;
}

.upload-panel_submit-button {
  background-color: #ffFFFF;
  padding: 15px 20px;
  font-size: 14px;
  color: #fff;
  font-family: "Roboto Medium";
  min-width: 240px;
  //border: 10px solid;
  //border-image-slice: 1;
  border-radius: 1px;
  //border-width: 4px;
  //border-image-source: linear-gradient(to left, #743ad5, #d53a9d);
  //border-image-source: linear-gradient(to left, #464af1, #3d92b7);
  background-color: #A1C6FA;
}
.upload-panel_submit-button:hover {
  //border-image-source: linear-gradient(to right, #743ad5, #d53a9d);
  //border-image-source: linear-gradient(to right, #f5235b, #ff8daa);
 // border-image-source: linear-gradient(to left, #464af1, #3035e8);
  background-color: #3171F6;
  color: #FFFFFF;
}


.file-viewer {
  display: grid;
  align-content: center;
  justify-content: center;
  grid-template-columns: repeat(5, calc(90% / 5));
  grid-gap: 10px;
  flex-wrap: wrap;
  margin: 20px auto;
}
.file-viewer-file {
  width: 100%;
  height: 140px;
  border: 1px solid black;
  background-size: cover;
  background-position: 50%;
  cursor: pointer;
}

.avatar-upload-percents {
  font-size: 40px;
  color: #ffffff;
}

.avatar-upload-photo-wrap {
  background-size: contain;
  width: 70vw;
  min-height: 200px;
  max-width: 800px;
  @media (max-width: 768px) {
    padding: 0;
    width: 80vw;
    max-width: 70vw;
    border: 1px solid red;
  }
}

.avatar-upload-photo {
  max-width: 100%;
}