.light-theme {
  --tableFontColor: #333333;
  --tableBorder: 1px solid #f7f7f7;
  --stripedOddBackground: #FFFFFF;
  --stripedEvenBackground: #EEEEEE;
  --hoverBackground: #EEEEEE;
  --hoverColor: #000000;
}
.dark-theme {
  --tableFontColor: #c9c9c9;
  --tableBorder: 1px solid #414141;
  --stripedOddBackground: #2e3238;
  --stripedEvenBackground: #393D45;
  --hoverBackground: #242a33;
  --hoverColor: #EEEEEE;
}

.porabote-table-wrap {
  box-sizing: border-box;
  position: relative;
  overflow: auto;
  overflow-y: auto;
  overflow-x: hidden !important;
  max-width: 100%;
  box-sizing: border-box;
  padding: 0;
  @media (max-width: 768px) {
    border: 1px solid #e3d5c0;
  }
}
.porabote-table {
  overflow: auto;
  overflow-x: visible !important;
  position: relative;
  max-width: calc(100vw - 150px);
  box-sizing: border-box;
  @media (max-width: 768px) {
    font-size: 16px;
    max-width: inherit;
  }
}

.porabote-row {
  display: grid;
  font-size: 1rem;
  color: var(--tableFontColor);
}
.porabote-row:last-child {
  border-bottom: var(--tableBorder);
}
.porabote-row.head:hover { background: none; }
.porabote-row:hover > .porabote-table__cell  {
  background: var(--hoverBackground);
  color: var(--colorBackground);
}
.porabote-row.head .porabote-table__cell  {
  color: #EBB867;
  font-size: 1em;
  white-space: nowrap;
  padding: 14px 20px;
}

.porabote-table::-webkit-scrollbar {
  width: 12px;               /* width of the entire scrollbar */
}
.porabote-table::-webkit-scrollbar-thumb {
  background-color: #b8b8b8;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 0;  /* creates padding around scroll thumb */
  max-height: 3px;
}


.porabote-table::-webkit-scrollbar {
  height: 6px;
  width: 0px;
  background: #b1b1b1;
}

.porabote-table::-webkit-scrollbar-thumb {
  background: #8c8c8c;
  -webkit-border-radius: 1ex;
}

.porabote-table::-webkit-scrollbar-corner {
  background: #8c8c8c;
}



.porabote-table__cell, .porabote-row .porabote-table__cell  {
  padding: 14px 20px;
  border: var(--tableBorder);
  border-right: 0;
  border-bottom: 0;
  min-width: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--tableFontColor);
}
.porabote-row .porabote-table__cell:last-child {
  border-right: var(--tableBorder);
}
.porabote-row .porabote-table__cell:first-child {
  padding-left: 20px;
}
.porabote-table.striped .porabote-table__cell, .porabote-table.striped .porabote-row .porabote-table__cell {
  border: 0;
}

.porabote-table.striped .porabote-row:nth-child(odd) {background: var(--stripedOddBackground)}
.porabote-table.striped .porabote-row:nth-child(even) {background: var(--stripedEvenBackground)}

.porabote-table__cell  .word-break,
.word-break {
  word-break: break-all;
}