.light-theme {
  --inputFontColor: #000000;
  --inputBackgroundColorHover: #333333;
  --inputBackgroundColor: #FFFFFF;
  --inputBorder: 1px solid #e3e3e3;
  --inputBorderRadius: 2px;
}
.dark-theme {
  --inputFontColor: #e3ddd4;
  --inputBackgroundColor: none;
  --inputBorder: 0;
  --inputBorderRadius: 8px;
}

.on_form.middle {
  max-width: 1240px;
  margin: 0 auto;
}

.form-item {
  display: block;
  position: relative;
  border: 1px solid #948B7D;
  padding: 0;
}

.form-item.no_padding {
  padding: 6px 0;
}

.form-item__select_native {
  visibility: hidden;
  display: none;
}
.form-item__select_native {
  visibility: hidden;
}

.form-item__select_custom {
  position: relative;
  display: flex;
  border: 0 solid #e5e5e5;
  padding: 0;
  justify-content: space-between;
  align-items: center;
  flex-basis: 100%;
}

.form-item__select_custom__input {
  padding: 4px 36px 4px 12px;
  font-size: 15px;
  color: var(--inputFontColor);
  border: 0;
  width: 100%;
  line-height: 26px;
  min-width: 60px;
  height: 26px;
  background: transparent;
}
.form_fieldset.small .form-item__select_custom__input {
  min-height: 24px;
  max-height: 24px;
  font-size: 12px;
}

div.form-item__select_custom__input {
  overflow: hidden;
}

.form-item__select_custom__input.toggle {
  cursor: pointer;
}

.form-item__select_custom__input:focus {
  border: 0 solid #e5e5e5;
  box-shadow:  0 0 3px 0 #dbdfe2;
}

.form-item__select_custom.disable { border: 0 solid #c7c9cc; background: #dadada; }

.form-item__select_custom__toggle {
  position: absolute;
  top: 2px;
  right: 5px;
  display: flex;
  align-items: center;
  width: 30px;
  height: 32px;
  cursor: pointer;
}
.form_fieldset.small .form-item__select_custom__toggle {
  min-height: 20px;
  max-height: 20px;
  width: 20px;
}


.form-item__select_custom__icon {
  display: block;
  border-left: 1px solid #c7c9cc;
  width: 30px;
  height: 20px;
  background-image: url("./svg/input/key_down.svg");
  background-size: 10px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.form-item__select__drop_blok {
  position : absolute;
  top: 0;
  background: #fff;
  border: 1px solid #f6f7f8;
  border-radius: 2px;
  -webkit-box-shadow: 0 7px 5px 0 rgba(201,201,201,1);
  -moz-box-shadow: 0 7px 5px 0 rgba(201,201,201,1);
  box-shadow: 0 6px 8px 0 rgba(201,201,201,0.2);
  transition : 0.1s ease all;
  visibility : hidden;
  padding: 10px 0;
  max-height: 250px;
  overflow: scroll;
  width: 100%;
  font-family: Helvetica, Sans_Serif, serif;
}

.form-item__select__drop_blok span {
  display: flex;
  flex-flow: column nowrap;
}

.dropSelectMinuts {
  display: flex;
  flex-flow: column nowrap;
  background: #fff;
  width: auto;
  padding: 10px 0 10px 0;
  font-size: 0.8rem;
  transition: 0.3s ease all;
}


.form-item__select__drop_link, .minute_set_value {
  display: flex;
  padding: 6px 10px 6px 10px;
  font-size: 14px;
  cursor: pointer;
  color: #191919;
  text-decoration: none;
  align-content: flex-end;
  align-items: flex-end;
  justify-content: flex-end;
  font-family: Helvetica, Sans_Serif, serif;
  line-height: 18px;
}

.form-item__select__drop_link{
  justify-content: flex-start;
  padding-left: 20px;
}

.form-item__select__drop_link.active,
.minute_set_value.active {
  background-size: 10px;
  background-repeat: no-repeat;
  background-position: 20px center;

}

.form-item__select__drop_link:hover, .minute_set_value:hover {
  cursor: pointer;
  background: #fefde1;
}

.form-item__select_custom__no_result {
  padding: 2px 10px 2px 20px;
  font-size: 13px;
  color: #a2a2a2;
  font-family: Helvetica, Sans_Serif, serif;
  width: 100%;
  flex-basis: 100%;
}

.on_select__finder {
  display: none;
}

.on_select {
  border: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  height: 34px;
  font-size: 14px;
  padding: 4px;
  cursor: pointer;
}

/* 01_02_2018 Form Style */

.form_fieldset {
  display: block;
  grid-column-gap: 0;
  padding: 0;
}
.form_fieldset .form-item {
  padding-left: 0;
}
.form_fieldset .form-item:first-child {
  padding-left: 0;
}

.form_fieldset.four { grid-template-columns: 25% 25% 25% 25%;}
.form_fieldset.two { grid-template-columns: 50% 50%;}
.third_one { grid-template-columns: 30% 60%;}

.form_fieldset__title {
  border-bottom: 1px solid #eef2f7;
  padding-bottom: 5px;
  padding-top: 14px;
  font-size: 15px;
  font-family: 'Roboto Medium', serif;
}
.form_fieldset__title:first-child, .form_fieldset__title.first { padding-top: 2px;}


.form_fieldset__title.small {
  padding-bottom: 2px;
  font-size: 13px;
  line-height: 20px;
  border: 0;
}

.tags_info_board{
  display: flex;
  align-content: stretch;
  align-items: stretch;
  padding: 10px;
}
.tags_board__items {
  padding-left: 10px;
  display: flex;
  align-items: flex-end;
  align-content: flex-end;
  justify-content: flex-end;
}
.tags_board__item {
  font-size: 15px;
  padding-left: 24px;
  color: #000;
  background-image: url('./svg/input/tick.svg');
  background-repeat: no-repeat;
  background-size: 18px;
}



.form-item__line {
  display: flex;
}

.form-item__info {
  position: relative;
  display: block;
}
.form-item__info {
  grid-column-start: 1;
  grid-column-end: 3;
}

.form-item__input, .form-item__inputarea {
  width: 100%;
}
.form-item__date:disabled, .form-item__input:disabled {
  color: #212121;
  border: 0 solid #c7c9cc; background: #dadada;
  cursor: help;
}

.form-item__input_wrap {
  position: relative;
  display: flex;
  font-size: 12px;
  flex: 1;
  padding: 2px;
  flex-grow: 2;
  min-height: 34px;
  min-width: 40px;
  line-height: 28px;
  background: var(--inputBackgroundColor);
  border: var(--inputBorder);
  border-radius: var(--inputBorderRadius);
}

.form_fieldset.small .form-item__input_wrap {
  max-height: 24px;
  min-height: 24px;
}


.form-item__input_wrap.border_no { border: 0; }

.form-item__input_finder_wrap {
  position: relative;
  font-family: Helvetica, Sans_Serif, serif;
  display: flex;
  flex-flow: column nowrap;
  font-size: 12px;
  -ms-box-sizing:padding-box;
  -moz-box-sizing:padding-box;
  -webkit-box-sizing:padding-box;
  flex: 1;
  padding: 0;
  flex-grow: 2;
  box-shadow: none;
  min-height: 34px;
  min-width: 100px;
  line-height: 28px;
  width: 100%;
  box-sizing: border-box;
}
.form-item__input_finder {

  border: 1px solid #c7c9cc;
  border-radius: 2px;
  padding: 0 40px 0 10px;
  font-size: 14px;
  line-height: 34px;
  background-image: url('./svg/input/magnifying-grey.svg');
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: 98% 50%;
}
.form-item__input_finder:focus {
  background-image: url('./svg/input/magnifying-blue.svg');
}
.on_input__finder__items {
  border: 1px solid #c7c9cc;
  border-top: 0;
  padding: 20px 0;
  position: relative;
}

.on_input__finder__item {

  background-repeat: no-repeat;
  background-size: 18px;
  background-position: 98% 50%;
  padding: 6px 15px;
  cursor: pointer;
  display: block;
}
.on_input__finder__item.users { background-image: url('./svg/input/register_user.svg'); }
.on_input__finder__item.links { background-image: url('./svg/input/verification-mark.svg'); }


.on_input__finder__item:hover { background-color: #fafafa; }


.on_file__input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: 1;
}
.on_file__input:focus + label,
.on_file__input + label:hover {
  background-color: red;
}
.on_file__input + label {
  cursor: pointer;
}
.on_file__label {
  border: 1px solid rgba(0, 0, 0, 0.14);
  color: rgba(0, 0, 0, 0.24);
  color: #444;
  font-family: "Helvetica Neue", Helvetica, Arial, serif;
  font-size: 14px;
  line-height: 40px;
  padding: 0 20px;
  border-radius: 4px;
  cursor: pointer;
  background: none no-repeat 10px 50%;
  background-size: 20px;
  display: block;
}

.on_file__label.icon_upload_file__grey_x_white {
  background-image: url('./svg/input/on-button_icon-upload-file__grey.svg');
  background-size: 24px;
  padding: 12px 12px 12px 50px;
  border: 1px solid #D9DBDB;
  margin-left: 0;
  background-position: 15px 50%;
}
.on_file__label.grey_stroke_x_yellow_fill:hover {
  background: #FFDC33;
  border: 1px solid #FFDC33;
}
.on_file__label.icon_upload_file__grey_x_white:hover {
  background-image: url('./svg/input/on-button_icon-upload-file__white.svg');
  background-repeat: no-repeat;
  background-position: 15px 50%;
  background-size: 24px;

}

.form_fieldset .form-item.on_file__wrap {
  padding-left: 0;
}

.form-item__time_wrap {
  position: relative;
  font-family: Helvetica, Sans_Serif, serif;
  display: flex;
  border: 1px solid #c7c9cc;
  border-radius: 2px;
  font-size: 12px;
  -ms-box-sizing:padding-box;
  -moz-box-sizing:padding-box;
  -webkit-box-sizing:padding-box;
  flex: 1;
  padding: 0;
  height: 34px;
  flex-grow: 2;
  box-shadow: none;
  min-height: 34px;
  min-width: 80px;
  max-width: 80px;
  line-height: 28px;
}
.form-item__time, .form-item__date {
  padding: 4px 12px;
  font-size: 15px;
  color: #111;
  border: 0;
  min-width: 40px;
}
.form-item__date {
  border-left: 1px solid #c7c9cc;
  padding-right: 6px;
  width: 120px;
}
.form-item__time {
  padding-right: 4px;
  width: 40px;
}

.form-item__date_wrap {
  position: relative;
  font-family: Helvetica, Sans_Serif, serif;
  display: flex;
  border: 1px solid #c7c9cc;
  font-size: 12px;
  -ms-box-sizing:padding-box;
  -moz-box-sizing:padding-box;
  -webkit-box-sizing:padding-box;
  flex: 1;
  padding: 0;
  height: 34px;
  flex-grow: 2;
  box-shadow: none;
  min-height: 34px;
  min-width: 120px;
  max-width: 140px;
  line-height: 28px;
  border-radius: var(--inputBorderRadius);
}
.form-item__date_wrap .form-item__date {
  width: 84px;
  border: 0;
}

.form-item__input {
  padding: 4px 20px;
  font-size: 18px;
  border: 0;
  line-height: 56px;
  min-width: 40px;
  background: transparent;
  color: var(--inputFontColor);
}

.form_fieldset.small .form-item__input {
  font-size: 13px;
  padding-left: 8px;
}

.form-item__input.border_slide {
  padding-left: 2px;
  border-bottom: 1px solid #c7c9cc;transition: border-width 0.6s linear;
  transition-property: opacity, left, top, height;
  transition-duration: 3s, 5s, 3s, 5s;
}


.form-item__input:read-only {
  color: #5d5757;
  background-color: #fdfdfd;
}

.form-item__inputarea_wrap {
  position: relative;
  font-family: 'Museo Regular', serif;
  display: flex;
  border: 1px solid #c7c9cc;
  border-radius: 0;
  font-size: 12px;
  -ms-box-sizing:padding-box;
  -moz-box-sizing:padding-box;
  -webkit-box-sizing:padding-box;
  padding: 0;
  box-shadow: none;
  min-height: 100px;

}

.form-item__inputarea_wrap.on_ckeditor {
  border: 0;
}


.form-item__inputarea {
  padding: 4px 12px;
  font-size: 15px;
  color: #111;
  min-width: 80px;
  width: 100%;
  border: 2px solid #fff;
}
.form-item__inputarea:focus {
  border: 2px solid #E9E9E9;
}

.form-item__select_wrap {
  position: relative;
  display: flex;
  border: 1px solid #c7c9cc;
  min-height: 25px;
}

.form_fieldset.small .form-item__select_wrap {
  min-height: 24px;
  max-height: 24px;
}



/* Теги.. */
.input_groups__item {
  border: 1px solid #fff;
  margin-top: 2px;
  font-size: 11px;
  background: #0a7ad6;
  color: #fff;
  padding: 6px 10px 6px 6px;
  border-radius: 3px;
  display: flex;
  align-items: center;
}
.form-item__info.labels {
  display: flex;
  flex-flow: row wrap;
  padding: 6px 0 0 0;
  height: auto;
}
.input_groups__item__delete {
  display: inline;
  width: 16px;
  height: 16px;
  background-image: url('./svg/input/close_white.svg');
  background-size: 10px;
  background-repeat: no-repeat;
  background-position: 50%;
  cursor: pointer;
}
.input_groups__item__title {
  padding-left: 6px;
  display: inline-block;
  line-height: 22px;
}

.input_editable_noactive {
  border: 0;
  background: none;
  font-size: 0.8rem;
  border-bottom: 1px dashed;
  color: #2ed3c5;
  cursor: pointer;
}
.input_editable_active {
  background: #fff;
  font-size: 0.9rem;
  border: 1px solid #eef2f7;
  cursor: text;
  color: #8e8e8f;
  padding: 5px;
}

.circle_arrow {
  border: 1px solid #f7f7f7;
  display: flex;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 6px;
  margin-left: 7px;
}

.form-item__input_error {
  font-size: 0.85em;
  color: #ff4897;
  padding: 4px 0;
}