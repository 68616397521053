.porabote-modal-dialog-wrap {
  position: fixed;
  justify-content: center;
  align-items: center;
  align-content: center;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100vw;
  box-sizing: border-box;
  opacity: 0;
  pointer-events: none;
  display: flex;
  height: 100vh;
}
.porabote-modal-dialog-wrap.active {
  opacity: 1;
  pointer-events: all;
}

.porabote-modal-dialog {
  max-width: 90vw;
  min-height: 100px;
  background-color: #111111;
  font-weight: 600;
  color: #e8e8e8;
  box-shadow: 15px 15px 15px -15px rgba(0, 0, 0, 0.6);
  border: 1px solid #070707;
  border-radius: 6px;
  transform: scaleY(0);
  transform-origin: center;
  transition: transform 0.26s ease;
  max-height: 90vh;
  overflow: auto;
}
.porabote-modal-dialog.active {
  transform: scaleY(1);
}

.porabote-modal-dialog-title {
  font-size: 1.6em;
  text-align: center;
  padding: 40px;
}

.porabote-modal-dialog-buttons-panel {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding: 0 20px 30px 20px;
}
.porabote-modal-dialog-button {
  background-color: #1c1c1c;
  font-size: 16px;
  padding: 20px 30px;
  cursor: pointer;
  margin-right: 20px;
  box-shadow: 15px 15px 15px -15px rgba(0, 0, 0, 0.6);
  color: #a2a2a2;
  border-radius: 6px;
}
.porabote-modal-dialog-button:hover {
  color: #fff;
}

.porabote-modal-dialog-button:last-child {
  margin-right: 0;
}