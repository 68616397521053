.form-item.input-medium {
  border: 0;
}

.input-medium .form-item__label{
  top: 0;
  left: 0;
  position: relative;
  padding: 0 0 6px 10px;
  background: none;
  font-size: 12px;
  text-align: left;
  display: block;
  margin: 30px 0 0 0;
  height: auto;
}

.input-medium .form-item__input_wrap {
  border: 1px solid #948B7D;
  margin-bottom: 20px;
}

.avatar_upload_input {
  cursor: pointer;
}